<template lang="html">
  <div>
    <div class="title-area mt-s">
      <h2 class="title-b">알림 목록</h2>
    </div>
    <div class="search-area mt-s">
      <div class="search-item">
        <div class="datepicker-in-box flex items-center py-1">
          <i class="icon-img ic-calendar"></i>
          <t-datepicker
            class="element-input-date"
            v-model="searchDate"
            range
            :clearable="false"
            date-format="Y-m-d"
            user-format="Y-m-d"
            rangeSeparator=" ~ "
            @hidden="changeDateRange"
          >
          </t-datepicker>
        </div>

        <t-rich-select
          v-if="getUserRole === 'master'"
          :options="companySearchList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-48"
          textAttribute="companyName"
          valueAttribute="companyGuid"
          :hideSearchBox="true"
          v-model="companySearchGuid"
          @change="selectCompany"
          placeholder="고객사/운용부서"
          noResultsText="등록된 고객사가 없습니다"
          :clearable="true"
        >
        </t-rich-select>

        <t-rich-select
          :options="siteSearchList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-48"
          textAttribute="siteName"
          valueAttribute="siteGuid"
          :hideSearchBox="true"
          v-model="siteSearchGuid"
          @change="selectSite"
          placeholder="관리구역"
          noResultsText="등록된 관리구역이 없습니다."
          :clearable="true"
        >
        </t-rich-select>

        <t-rich-select
          :options="mapSearchList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-48"
          textAttribute="mapName"
          valueAttribute="mapGuid"
          :hideSearchBox="true"
          v-model="mapSearchGuid"
          placeholder="상세위치"
          noResultsText="등록된 상세위치가 없습니다"
          :clearable="true"
        >
        </t-rich-select>

        <t-rich-select
          :options="eventTypeList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-48"
          textAttribute="mapName"
          valueAttribute="mapGuid"
          :hideSearchBox="true"
          v-model="selectEventType"
          placeholder="알림유형"
          :clearable="true"
        >
        </t-rich-select>

        <input class="element-input w-52" type="text" v-model="deviceSearchID" placeholder="단말기 아이디">

        <button type="button" class="element-btn bg-blue w-150" @click="searchCheckEvent()">검색</button>
      </div>
      <div class="btn-item">
        
      </div>
    </div>

    <div class="table-head mt-xs">
      <div class="total-item">
        <span class="total">Total {{totalCount}}</span>
      </div>
      <div class="btn-item">
        
      </div>
    </div>

    <div class="table-a">
      <table>
        <colgroup>
          <col style="width:80px">
          <col style="width:auto">
          <col style="width:auto">
          <col style="width:150px">
          <col style="width:100px">
          <col style="width:180px">
          <col style="width:180px">
          <col style="width:180px">
          <col style="width:180px">
        </colgroup>
        <thead>
        <tr>
          <th scope="col">No.</th>
          <th scope="col">일시</th>
          <th scope="col">확인일시</th>
          <th scope="col">단말ID</th>
          <th scope="col">구분</th>
          <th scope="col">고객사/운용부서</th>
          <th scope="col">관리구역</th>
          <th scope="col">상세위치</th>
          <th scope="col">계정ID</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(eItem, idx) in checkEventList" :key="idx">
          <td>{{ totalCount - idx - (limit * ( currentPage - 1 )) }}</td>
          <td>{{eItem.insertDate | moment('yyyy-MM-DD HH:mm:ss')}}</td>
          <td>{{eItem.checkDate | moment('yyyy-MM-DD HH:mm:ss')}}</td>
          <td>{{eItem.deviceID}}</td>
          <td><span class="txt-blue">{{eventTypeList[eItem.eventType]}}</span></td>
          <td>{{eItem.companyName}}</td>
          <td>{{eItem.siteName}}</td>
          <td>{{eItem.mapName}}</td>
          <td>{{eItem.checkerID}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    
    <paging-component :totalCount="totalCount" :limit="limit" :currentPage="currentPage" @chgCurrentPage="chgCurrentPage"></paging-component>

    


  </div>
</template>

<script>
import PagingComponent from "@/components/elements/paging"

import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

// import { getDateRangeArray } from "@/utils/config"
import { eventTypeDesc } from "@/references/config"

export default {
  props: {
    deviceInfo: { type: Object }
  },
  async created () {
    this.searchDate.push(this.$moment().startOf('month').format('YYYY-MM-DD'))
    this.searchDate.push(this.$moment().format('YYYY-MM-DD'))

    this.getCompanyListAll();
    if(this.getCompanyGuid != "0") {
      this.companySearchGuid = this.getCompanyGuid
      this.getSiteListAll()
    }

    this.makeSearchParams()
    
    // this.getCheckEvent();
  },
  components: {
    PagingComponent
  },
  computed: {
    ...mapGetters(["getIsOverlay","getUserRole","getCompanyGuid"]),
  },
  data () {
    return {
      searchDate: [],
      companySearchGuid:'',
      siteSearchGuid:'',
      mapSearchGuid:'',
      deviceSearchID: '',
      selectEventType: '',


      companySearchList: [],
      siteSearchList: [],
      mapSearchList: [],
      eventTypeList: eventTypeDesc,

      limit: 10,
      currentPage: 1,
      totalCount: 0,
      checkEventList: [],

      isCheckAll: false,
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay"]),
    chgCurrentPage (num) {
      this.currentPage = num
      this.getCheckEvent()
    },
    changeDateRange () {
    },
    selectCompany () {
      this.siteSearchGuid = ''
      this.getSiteListAll()
    },
    selectSite () {
      this.mapSearchGuid = ''
      this.getMapListAll()
    },
    selectMap () {
    },
    async makeSearchParams () {
      if(this.deviceInfo.companyGuid) {
        this.companySearchGuid = this.deviceInfo.companyGuid
        this.siteSearchGuid = ''
        await this.getSiteListAll()
      }
      if(this.deviceInfo.siteGuid) {
        this.siteSearchGuid = this.deviceInfo.siteGuid
        this.mapSearchGuid = ''
        await this.getMapListAll()
      }
      if(this.deviceInfo.mapGuid) {
        this.mapSearchGuid = this.deviceInfo.mapGuid
      }
      if(this.deviceInfo.deviceID) {
        this.deviceSearchID = this.deviceInfo.deviceID
      }


      if(!this.$route.query.guid) {
        if(!this.deviceInfo.companyGuid) {
          this.searchCheckEvent()
        }
      }
      else {
        if(this.deviceInfo.companyGuid) {
          this.searchCheckEvent()
        }
      }

      

      // setTimeout(() => {
      //   if(this.deviceInfo.siteGuid) {
      //     this.siteSearchGuid = this.deviceInfo.siteGuid
      //     this.selectSite()
      //   }
      // }, 500)
      
      // setTimeout(() => {
      //   if(this.deviceInfo.mapGuid) {
      //     this.mapSearchGuid = this.deviceInfo.mapGuid
      //   }
      // }, 1000)
    },
    async getCompanyListAll () {
      let reqObj = {}
      reqObj.target = "/company/listall";
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.companySearchList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.companySearchList = result.msg.retData
      }
    },

    async getSiteListAll () {
      let reqObj = {}
      reqObj.target = `/site/list/${this.companySearchGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.siteSearchList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.siteSearchList = result.msg.retData
      }
    },

    async getMapListAll () {
      let reqObj = {}
      reqObj.target = `/map/list/${this.siteSearchGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.mapSearchList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.mapSearchList = result.msg.retData
      }
    },

    searchCheckEvent () {
      this.currentPage = 1
      this.getCheckEvent()
    },

    async getCheckEvent () {
      let startDate = this.$moment(this.searchDate[0] + " 00:00:00").valueOf();
      let endDate = this.$moment(this.searchDate[1] + " 23:59:59").valueOf();

      let reqObj = {}
      reqObj.target = `/event/checklist`;
      reqObj.method = "get";
      reqObj.params = {
        "companyGuid": this.companySearchGuid ? this.companySearchGuid : '0',
        "siteGuid": this.siteSearchGuid ? this.siteSearchGuid : '0',
        "mapGuid": this.mapSearchGuid ? this.mapSearchGuid : '0',
        "deviceID": this.deviceSearchID ? this.deviceSearchID : '',
        "eventType": this.selectEventType ? this.selectEventType : '',
        "startTimestamp": startDate,
        "endTimestamp": endDate,
        "offset": this.currentPage - 1,
        "limit": this.limit
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        let tempList = result.msg.retData.content
        let addSelector = tempList.map(data => ({ ...data, selector: false}))
        this.checkEventList = [...addSelector]

        this.totalCount = result.msg.retData.totalElements
      }
    },
  },
  watch: {
    'deviceInfo': function () {
      this.makeSearchParams()
    },
  }


}
</script>
