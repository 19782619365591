<template lang="html">
  <div class="content-box">
    <div class="component-tab-a">
      <a @click="chgTabIndex(1)" class="tab-item cursor-pointer" :class="tabIndex === 1 ? 'active' : ''">미확인 알림</a>
      <!-- 20240429 이너트론 요청으로 확인 탭 없이 미확인 탭만 나오게 -->
      <!-- <a @click="chgTabIndex(2)" class="tab-item cursor-pointer" :class="tabIndex === 2 ? 'active' : ''">확인 알림</a> -->
    </div>

    <!-- 단말 관리 TAB START -->
    <div v-if="tabIndex === 1">
      <EventActiveList :deviceInfo="deviceInfo"></EventActiveList>
    </div>
    <!-- 단말 관리 TAB END -->

    <!-- 펌웨어 관리 TAB START -->
    <div v-if="tabIndex === 2">
      <EventCheckList :deviceInfo="deviceInfo"></EventCheckList>
    </div>
    <!-- 펌웨어 관리 TAB End -->

  </div>
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

import EventActiveList from "@/pages/event/EventActiveList"
import EventCheckList from "@/pages/event//EventCheckList"

export default {
  created () {
    console.log(this.$route.query.guid)
    if(this.$route.query.guid != '') this.getDeviceInfo()
  },
  components: {
    EventActiveList,
    EventCheckList,
  },
  computed: {
    ...mapGetters(["getIsOverlay","getUserRole"]),
  },
  data () {
    return {
      tabIndex: 1,
      deviceInfo: {}
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay"]),
    chgTabIndex (idx) {
      this.tabIndex = idx;
    },
    async getDeviceInfo () {
      let reqObj = {}
      reqObj.target = `/device/info/${this.$route.query.guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.deviceInfo = {}
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.deviceInfo = result.msg.retData
      }
    },
  }
}
</script>